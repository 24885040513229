<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">专栏管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">个人专栏</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="专栏名称" class="searchboxItem ci-full">
              <span class="itemLabel">专栏名称:</span>
              <el-input
                v-model="miniSubscribeSpecialName"
                type="text"
                size="small"
                clearable
                placeholder="请输入专栏名称"
              />
            </div>

            <!-- 选择上架状态 -->
            <div title="上架状态" class="searchboxItem ci-full">
              <span class="itemLabel">上架状态:</span>
              <el-select
                v-model="miniSubscribeSpecialPut"
                placeholder="请选择上架状态"
                clearable
                size="small"
              >
                <el-option label="已上架" :value="true"> </el-option>
                <el-option label="未上架" :value="false"> </el-option>
              </el-select>
            </div>
             <!-- 选择是否在专栏显示 -->
             <div title="是否在专栏显示" class="searchboxItem ci-full">
              <span class="itemLabel">是否在专栏显示:</span>
              <el-select
                v-model="showState"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option label="是" :value="true"> </el-option>
                <el-option label="否" :value="false"> </el-option>
              </el-select>
            </div>
             <!-- 选择ios能否支付 -->
             <div title="ios能否支付" class="searchboxItem ci-full">
              <span class="itemLabel">ios能否支付:</span>
              <el-select
                v-model="iosPayState"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option label="是" :value="true"> </el-option>
                <el-option label="否" :value="false"> </el-option>
              </el-select>
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >添加专栏</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="专栏名称"
                align="center"
                prop="miniSubscribeSpecialName"
                show-overflow-tooltip
                min-width="100"
              />

              <el-table-column
                label="专栏售价"
                align="center"
                prop="miniSubscribeSpecialPrice"
                show-overflow-tooltip
                min-width="100"
              />

              <el-table-column
                label="有效课程数"
                align="center"
                prop="miniSubscribeSpecialPernum"
                show-overflow-tooltip
                min-width="80"
              />

              <el-table-column
                label="购买学员数"
                align="center"
                prop="miniSubscribeSpecialBuynum"
                show-overflow-tooltip
                min-width="80"
              />

              <el-table-column
                label="上架状态"
                align="center"
                prop="miniSubscribeSpecialPut"
                show-overflow-tooltip
                width="80"
              >
                <template slot-scope="scope">
                  <span
                    :style="{
                      color:
                        scope.row.miniSubscribeSpecialPut
                          ? '#92C74F'
                          : '#DF354D',
                    }"
                  >
                    {{
                      scope.row.miniSubscribeSpecialPut
                        ? "已上架"
                        : "未上架"
                    }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                label="上次更新时间"
                align="center"
                prop="updateTime"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.updateTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="是否在专栏显示"
                align="center"
                prop="showState"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.showState"
                    @change="showStateChange(scope.row.miniSubscribeSpecialId)"
                    >
                  </el-switch>
                  {{ scope.row.showState?'是':'否'}}
                </template>
              </el-table-column>
              <el-table-column
                label="ios能否支付"
                align="center"
                prop="iosPayState"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.iosPayState"
                    @change="iosPayStateChange(scope.row.miniSubscribeSpecialId)"
                    >
                  </el-switch>
                  {{ scope.row.iosPayState?'是':'否'}}
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="center"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                align="center"
                fixed="right"
                width="260"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleCreate('edit', scope.row.miniSubscribeSpecialId)"
                    >编辑
                  </el-button>

                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="offOrOnTheShelf(scope.row)"
                    >{{
                      scope.row.miniSubscribeSpecialPut? "下架" : "上架"
                    }}
                  </el-button>

                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    :disabled="scope.row.miniSubscribeSpecialBuynum>0"
                    @click="handleDel(scope.row.miniSubscribeSpecialId)"
                    >删除</el-button
                  >

                  <el-popover
                    placement="left"
                    width="200"
                    trigger="hover"
                    :content="'/pages/packageA/specialCoursesDetail/index?specialSubjectId='+ scope.row.miniSubscribeSpecialId"
                  >
                    <el-button
                      type="text"
                      style="padding: 0px 5px"
                      size="mini"
                      slot="reference"
                      >查看链接
                    </el-button>
                  </el-popover>

                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="viewSharingCode(scope.row)"
                    >查看分享码
                  </el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 查看分享码对话框 -->
    <el-dialog
      title="分享码"
      :visible.sync="codeDialogVisible"
      width="480px"
      @closed="dialogClose"
    >
      <img class="sharingCode" :src="sharingCode" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      miniSubscribeSpecialName: "", //专栏名称
      miniSubscribeSpecialPut: "", // 专栏上架状态
      codeDialogVisible: false, // 查看分享码对话框可见性
      sharingCode: "", // 分享码地址
      showState: "", // 是否在专栏显示
      iosPayState: "", // ios能否支付
    };
  },
  computed: {},
  methods: {
    // 获取列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        miniSubscribeSpecialName: this.miniSubscribeSpecialName,
        miniSubscribeSpecialPut: this.miniSubscribeSpecialPut,
        showState: this.showState,
        iosPayState: this.iosPayState,
      };
      this.doFetch(
        {
          url: "/miniapp/subscribeSpecial/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 新增&编辑
    handleCreate(stu, miniSubscribeSpecialId = "") {
      this.$router.push({
        path: "/web/miniApp/thematicGrEdit",
        query: { miniSubscribeSpecialId, stu },
      });
    },
    showStateChange(miniSubscribeSpecialId){
      this.$post(
        "/miniapp/subscribeSpecial/update/showState",
        { miniSubscribeSpecialId },3000, true, 2
      ).then((res) => {
        this.getData(-1);
      });
    },
    iosPayStateChange(miniSubscribeSpecialId){
      this.$post(
        "/miniapp/subscribeSpecial/update/iosPayState",
        { miniSubscribeSpecialId },3000, true, 2
      ).then((res) => {
        this.getData(-1);
      });
    },
    // 删除
    handleDel(miniSubscribeSpecialId) {
      this.$confirm("确定删除该专栏吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post(
            "/miniapp/subscribeSpecial/specialColumnDelete",
            { miniSubscribeSpecialId },3000, true, 2
          ).then((res) => {
            this.getData(-1);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 上架或者下架
    offOrOnTheShelf(row) {
      const { miniSubscribeSpecialId, miniSubscribeSpecialPut } = row;
      const state = miniSubscribeSpecialPut? "下架" : "上架";
      this.$confirm(`确定${state}该专栏吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post(
            "/miniapp/subscribeSpecial/updateState",
            { miniSubscribeSpecialId },3000, true, 2
          ).then(() => {
            this.getData(-1);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${state}`,
          });
        });
    },
    // 查看分享码
    viewSharingCode(row) {
      let miniSubscribeSpecialId = row.miniSubscribeSpecialId;
      this.$post(
        "/miniapp/subscribeSpecial/viewSharingCode",
        { miniSubscribeSpecialId },3000, true, 2
      ).then((res) => {
        this.sharingCode = res.message;
        this.codeDialogVisible = true;
      });
    },
    // 对话框关闭清空分享码
    dialogClose() {
      this.sharingCode = "";
    },
  },
  created() {
    
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh || val.query.refresh == "true") {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
.sharingCode {
  display: block;
  margin: 0 auto;
  width: 350px;
  height: 350px;
}
</style>
<style lang="less" scoped></style>